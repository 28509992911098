/* Fonts */

@font-face {
    font-family: proxima-nova;
    src: url("fonts/2F58FF_4_0.eot?#iefix") format("embedded-opentype"), url("fonts/2F58FF_4_0.woff") format("woff");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: proxima-nova;
    src: url("fonts/2F58FF_3_0.eot?#iefix") format("embedded-opentype"), url("fonts/2F58FF_3_0.woff") format("woff");
    font-style: normal;
    font-weight: 600;
}


/* Remove dotted border appearing after click*/

a:hover, a:active, a:focus {
    outline: 0;
}

.fa-trash-alt {
    color: #F55668;
    opacity: 0.7;
}

.fa-edit {
    color: #395A7A;
}


body {
    font-family: "proxima-nova", sans-serif;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #c1c3c7;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: rgba(74, 184, 88, .8);
}

input:focus + .slider {
    box-shadow: 0 0 1px rgba(74, 184, 88, .8);
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 12px;
}

.slider.round:before {
    border-radius: 50%;
}


/* Create dots with css <span class=dot> </span>*/

.dot-ok {
    height: 10px;
    width: 10px;
    background-color: #60bd58;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
}

.dot-warning {
    height: 10px;
    width: 10px;
    background-color: #edb10c;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
}

.dot-error {
    height: 10px;
    width: 10px;
    background-color: #f43e61;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
}

/* Span info*/

.info-ok {
    height: 20px;
    width: 40px;
    display: inline-block;
    background-color: rgb(221, 241, 222);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-image: none;
    background-size: auto auto;
    background-origin: padding-box;
    background-clip: border-box;
    border-radius: 10px;
    color: #4ab858;
    text-align: center;
    padding-top: 4px;

}

.info-ok span {
    color: #4ab858;
    font-size: 8px;
    font-weight: 600;
    text-align: center;
    display: block;
}

.exec-ok {
    height: 25px;
    width: 50px;
    display: inline-block;
    background-color: rgb(221, 241, 222);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-image: none;
    background-size: auto auto;
    background-origin: padding-box;
    background-clip: border-box;
    border-radius: 25px;
    color: #4ab858;
    text-align: center;
    padding-top: 8px;

}

.exec-ok span {
    color: #4ab858;
    font-size: 8px;
    font-weight: 600;
    text-align: center;
    display: block;
}


.info-warning {
    height: 20px;
    width: 40px;
    display: inline-block;
    background-color: #FFF4CE;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-image: none;
    background-size: auto auto;
    background-origin: padding-box;
    background-clip: border-box;
    border-radius: 10px;
    color: #FFC200;
    text-align: center;
    padding-top: 4px;

}

.info-warning span {
    color: #FFC200;
    font-size: 8px;
    font-weight: 600;
    text-align: center;
    display: block;
}

.exec-warning {
    height: 25px;
    width: 50px;
    display: inline-block;
    background-color: #FFF4CE;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-image: none;
    background-size: auto auto;
    background-origin: padding-box;
    background-clip: border-box;
    border-radius: 25px;
    color: #FFC200;
    text-align: center;
    padding-top: 8px;

}

.exec-warning span {
    color: #FFC200;
    font-size: 8px;
    font-weight: 600;
    text-align: center;
    display: block;
}

.info-error {
    height: 20px;
    width: 40px;
    display: inline-block;
    background-color: #FCD9E0;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-image: none;
    background-size: auto auto;
    background-origin: padding-box;
    background-clip: border-box;
    border-radius: 10px;
    color: #F43E61;
    text-align: center;
    padding-top: 4px;

}

.info-error span {
    color: #F43E61;
    font-size: 8px;
    font-weight: 600;
    text-align: center;
    display: block;
}

.exec-error {
    height: 25px;
    width: 50px;
    display: inline-block;
    background-color: #FCD9E0;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-image: none;
    background-size: auto auto;
    background-origin: padding-box;
    background-clip: border-box;
    border-radius: 25px;
    color: #F43E61;
    text-align: center;
    padding-top: 8px;

}

.exec-error span {
    color: #F43E61;
    font-size: 8px;
    font-weight: 600;
    text-align: center;
    display: block;
}

/* CSS Side Bar*/

.side-bar-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.side-bar-header i {
    color: #395A7A;
    font-size: 50px;
}

.side-bar-header span {
    font-size: 12px;
    color: #395a7a;
    font-weight: 400;
}

.side-bar-header a {
    text-decoration: none;
}

#user-email {
    font-weight: bold;
}

.icon-person-side-bar {
    margin-right: 12px;
}

.input-search-bar {
    height: 15%;
    display: flex;
    align-items: center;
}

.side-bar-main i {
    margin-right: 12px;
}

.side-bar-main span {
    font-size: 12px;
    color: #395a7a;
    font-weight: 400;
}

.input-search-bar input[type="text"], textarea {
    background-color: #f1f4f9;
    border-radius: 1px;
}


.slide-out {
    height: 85%;
    margin-bottom: 0;
}

.slide-out li {
    height: 10%;
}

.slide-out li a {
    display: flex;
    align-items: center;
}

.slide-out li a i {
    color: #395A7A;
}

.slide-out li span {
    font-size: 16px;
    color: #395a7a;
    font-weight: 500;
}

.fa-robot {
    font-size: 18px;
    color: #395A7A;
}

.scrollable-li {
    height: 73% !important;
}

.father-scrollable-li {
    height: 7% !important;
}

.scrollable-li {
    margin-left: 5px;
}

.scrollable-li nav {
    height: 100%;
}

.scrollable-li nav ul {
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    direction: rtl;
}

.scrollable-li nav ul li {
    margin-left: 20px;
    font-size: 13px;
    color: #395a7a;
    font-weight: 400;
    display: flex;
    direction: ltr;
}

/* style scroll in firefox*/

:root {
    scrollbar-color: rgb(210, 210, 210) rgb(255, 255, 255) !important;
    scrollbar-width: thin !important;
}

:root #dashboard {
    scrollbar-color: rgb(210, 210, 210) rgb(241, 244, 249) !important;
}


.bot-link {
    display: block !important;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #395A7A !important;
}

.div-bot-name {
    max-width: 80%;
}

.div-bot-status {
    width: 20%;
}

/* Side Bar Footer*/

.side-bar-footer ul {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
}

.side-bar-footer ul li {
    margin-bottom: 10px;
}

.side-bar-footer ul li a {
    font-size: 16px;
    color: #395a7a;
    font-weight: 500;
}

/* Breadcrumb header*/

.main-header h1 {
    margin-left: 5.5%;
    font-weight: 600;
    font-size: 22px;
    color: #395a7a;
}

/* Dashboard CSS*/

#dashboard {
    background: #f1f4f9;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    position: relative;
}

#container-dash-view-execs {
    background: #f1f4f9;
    height: 30%;
    width: 100%;
    margin-bottom: 1%;
    display: flex;
}

#container-dash-view-logs {
    background: white;
    height: 60%;
    width: 95%;
    border: 1px solid #C9D3DD;
    box-shadow: 0 0 10px 0 rgba(201, 211, 221, 0.4);
    margin: auto;

}

.container-exec-box {
    width: 96%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
}

.exec-box {
    height: 45%;
    width: 24%;
    background: white;
    border: 1px solid #C9D3DD;
    box-shadow: 0 0 10px 0 rgba(201, 211, 221, 0.4);
    display: flex;
    flex-direction: column;
    margin: 0.5%;
}

.info-exec-box {
    width: 49%;
    height: 50%;
    background: white;
    box-shadow: 0 1px 1px 0px rgba(0, 28, 36, 0.5);
    border-top: 1px solid #eaeded;
    display: flex;
    flex-direction: column;
    margin: 0.5%;
}

.info-exec-logs {
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    margin: 0.5%;
}

.info-exec-box-column {
    width: 99%;
    height: 50%;
    background: white;
    box-shadow: 0 1px 1px 0px rgba(0, 28, 36, 0.5);
    border-top: 1px solid #eaeded;
    display: flex;
    flex-direction: column;
    margin: 0.5%;
}

.info-exec-box-column-row {
    width: 50%;
    height: 100%;
    background: white;
    box-shadow: 0 1px 1px 0px rgba(0, 28, 36, 0.5);
    border-top: 1px solid #eaeded;
    display: flex;
    flex-direction: column;
    margin: 0.5%;
}

.info-exec-box-row {
    width: 100%;
    height: 50%;
    display: flex;
}

.exec-box-arrow-right {
    height: 100%;
    width: 2%;
    margin: auto;
    position: relative;
}

.exec-box-arrow-right i {
    position: absolute;
    bottom: 50%;
    color: #395a7a;
}

.exec-box-arrow-left {
    height: 100%;
    width: 2%;
    margin: auto;
    position: relative;
}

.exec-box-arrow-left i {
    position: absolute;
    bottom: 50%;
    color: #395a7a;
}

.box-extractor-name {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    font-weight: 400;
    font-size: 18px;
    color: #395a7a;
    text-align: center;
    margin: 14px;
}

.status-dash-view-exec {
    width: 80%;
    display: flex;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;

}

.status-dash-view-exec > span {
    position: absolute;
    right: 0;
}

.status-dash-view-exec-label {
    position: absolute;
    right: 0;
}

.status-dash-view-exec label {
    max-width: 80%;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    font-size: 12px;
    color: #395a7a !important;
    font-weight: 400;
}

.status-dash-view-exec label span {
    font-size: 12px;
    color: #c1c3c7;

}

.chart-exec-box {
    width: 100%;
    flex-grow: 1;
    position: relative;
}

.head-dash-view-logs {
    width: 95%;
    height: 14%;
    margin: auto;
    display: flex;
    align-items: center;
    border-bottom: 1px dotted #C9D3DD;
}

.content-dash-view-logs {
    width: 95%;
    height: 86%;
    margin: auto;
}

.row-dash-view-log {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
}


.head-column-dash-view-logs {
    display: inline-block;
    font-weight: bold;
    color: #7A7A7F;
    font-size: 14px;
}

.head-column-dash-view-logs.collector {
    text-align: start;
    width: 15%;
    margin-right: 10px;
}

.head-column-dash-view-logs.exec {
    text-align: start;
    width: 15%;
    margin-right: 10px;
}

.head-column-dash-view-logs.message {
    text-align: start;
    width: 70%;
    margin-right: 10px;
}

.row-column-dash-view-logs.collector {
    text-align: start;
    color: #395a7a;
    font-weight: 400;
    font-size: 14px;
    width: 15%;
    margin-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row-column-dash-view-logs.exec {
    text-align: start;
    font-size: 14px;
    color: #395a7a;
    width: 15%;
    margin-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row-column-dash-view-logs.message {
    text-align: start;
    font-size: 14px;
    color: #395a7a;
    width: 70%;
    margin-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* Extractor CSS*/

#extractor {
    background: #ffffff;
    width: 100%;
    height: 100%;
    border: 1px solid #C9D3DD;
    box-shadow: 0 0 10px 0 rgba(201, 211, 221, 0.4);
    margin: auto;
    position: relative;
}


.name-actions-collector {
    width: 95%;
    height: 10%;
    margin: auto;
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px dotted #C9D3DD;
}

#name-collector {
    width: 50%;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.1;
    color: #395a7a;
    text-rendering: optimizelegibility;
    text-overflow: ellipsis;
    overflow: hidden;
}

#name-collector a i {
    position: relative;
    /* font-size: 22px;
    top:3px;
    left: 5px; */
    color: #395a7a;
}

.actions-collector {
    position: absolute;
    right: 0;
    display: flex;
}

.btn-delete {
    width: 100px;
    height: 30px;
    margin-left: 4px;
    margin-right: 4px;
    color: #395a7a;
    background-color: #f1f4f9;
    font-size: 10px;
    font-weight: 600;
    border: 1px solid;
    border-color: #c9d3dd;
    border-radius: 3px;
}

.btn-add {
    width: 100px;
    height: 30px;
    margin-left: 4px;
    margin-right: 4px;
    color: #ffffff;
    background-color: rgba(74, 184, 88, .8);
    font-size: 10px;
    font-weight: 600;
    border-radius: 3px;
}

.btn-delete-vertical {
    width: 100px;
    height: 30px;
    margin-left: 4px;
    margin-right: 4px;
    color: #395a7a;
    background-color: #f1f4f9;
    font-size: 10px;
    font-weight: 600;
    border: 1px solid;
    border-color: #c9d3dd;
    border-radius: 3px;
    margin-bottom: 10px;
}

.btn-add-vertical {
    width: 100px;
    height: 30px;
    margin-left: 4px;
    margin-right: 4px;
    color: #ffffff;
    background-color: rgba(74, 184, 88, .8);
    font-size: 10px;
    font-weight: 600;
    border-radius: 3px;
    margin-bottom: 10px;
}

.btn-new-credentials {
    width: 120px;
    height: 40px;
    color: #395a7a;
    background-color: #f1f4f9;
    font-size: 10px;
    font-weight: 600;
    border: 1px solid;
    border-color: #c9d3dd;
    border-radius: 1px;
}


.info-collector {
    width: 93%;
    height: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
}

.edit-info-parallelism {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.column-info-parallelism {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
    border-right: solid 1px;
    padding-right: 30px;
    border-color: #cecece;
}

.column-info-webhook {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
    border-right: solid 1px;
    padding-right: 30px;
    border-color: #cecece;
    width: 100%;
}

.column-info-row-webhook {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
    width: 75%;
}

.column-info-row-status-webhook {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
    width: 25%;
}

.row-info-webhook {
    display: flex;
}

.old-parallelism {
    color: #395A7A;
}

.old-webhook {
    color: #395A7A;
}


.column-info-parallelism-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    flex-flow: column;
}

.column-info-parallelism label {
    margin-bottom: 1px;
    font-size: 14px;
    color: #c1c3c7;
    font-weight: 400;
}

.column-info-webhook label {
    margin-bottom: 1px;
    font-size: 14px;
    color: #c1c3c7;
    font-weight: 400;
}

.column-info-webhook-label {
    margin-bottom: 1px;
    font-size: 14px;
    color: #c1c3c7;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#info-parallelism {
    width: 100px;
    padding: 5px 12px 3px;
    height: 25px;
    color: #395A7A;
    border-radius: 1px 0 0 1px;
    background-color: #fff;
    border: 1px solid #C9D3DD;
    font-size: 13px !important;
}

#info-webhook {
    padding: 5px 12px 3px;
    height: 25px;
    color: #395A7A;
    border-radius: 1px 0 0 1px;
    background-color: #fff;
    border: 1px solid #C9D3DD;
    font-size: 13px !important;
}

.info-exec-status {
    height: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;

}

.info-exec {
    width: 96%;
    height: 97%;
    margin-left: 4%;
    display: flex;
    flex-flow: column;
}

.column-info-collector {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.column-info-collector-key {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px;
}

.column-info-collector label {
    margin-bottom: 1px;
    font-size: 14px;
    color: #c1c3c7;
    font-weight: 400;
}

.column-info-collector-key label {
    margin-bottom: 1px;
    font-size: 14px;
    color: #c1c3c7;
    font-weight: 400;
}

.exec-schedules-collector {
    width: 95%;
    margin: auto;
    margin-bottom: 40px;
}

.exec-schedules-collector-content {
    width: 90%;
    height: 60%;
    margin: auto;
}

.exec-schedules-collector-content-2 {
    width: 90%;
    height: 45%;
    margin: auto;
    position: relative;
}

#collector-runing-now {
    color: #395A7A;
    font-size: 14px;
    font-weight: 400;
}

#webhook-collector {
    color: #395A7A;
    font-size: 14px;
    font-weight: 400;
    display: block !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 80%;
}

.column-info-collector label i {
    position: relative;
    /* font-size: 22px;
    top: 3px;
    left: 5px; */
    color: #395A7A;
}


.dt-tabs-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    border-bottom: 1px solid #c1c3c7;
    font-size: 14px;
    font-weight: 400;
    position: relative;
}

.dt-tabs-list a {
    width: 20%;

}

.dt-tabs-title.is-active {
    border-color: #c1c3c7;
    margin-bottom: -1px;
    background-color: white;
}

.dt-tabs-title {
    width: 100%;
    margin-right: 20px;
    margin-bottom: -1px;
    padding: 8px 12px;
    color: #395a7a;
    font-weight: 600;
    text-align: center;
    border: 1px solid transparent;
    border-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    /* cursor: pointer; */
}

.head-extractor {
    width: 100%;
    height: 14%;
    margin: auto;
    display: flex;
    align-items: center;
    border-bottom: 1px dotted #C9D3DD;
    overflow: hidden;
    overflow-y: hidden;
    overflow-y: scroll;
}

.head-debug {
    width: 100%;
    height: 14%;
    margin: auto;
    display: flex;
    align-items: center;
    border-bottom: 1px dotted #C9D3DD;
}

.head-extractor-schedule {
    width: 100%;
    height: 14%;
    margin: auto;
    display: flex;
    align-items: center;
    border-bottom: 1px dotted #C9D3DD;
    overflow: hidden;
    overflow-y: hidden;
    overflow-y: scroll;
}

.head-column-exec {
    display: inline-block;
    font-weight: bold;
    color: #7A7A7F;
    font-size: 14px;
    text-align: start;
    width: 23.5%;
}

.head-column-exec.log {
    text-align: start;
    width: 6%;
}

.head-column-schedule {
    display: inline-block;
    font-weight: bold;
    color: #7A7A7F;
    font-size: 14px;
    text-align: start;
    width: 30%;
}

.head-column-schedule.actions {
    width: 10%;
    text-align: center;
}

.content-extractor-exec {
    width: 100%;
    height: 86%;
    overflow: hidden;
    overflow-y: hidden;
    overflow-y: scroll;
}

.content-extractor-schedule {
    width: 100%;
    height: 86%;
    overflow: hidden;
    overflow-y: hidden;
    overflow-y: scroll;
    position: relative;
}

.row-content-extractor-exec {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
}

.row-content-extractor-schedule {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
}

.row-content-extractor-schedule-2 {
    width: 100%;
    height: 12%;
    display: flex;
    align-items: center;
}

.row-column-content-extractor-exec {
    text-align: start;
    color: #395a7a;
    font-weight: 400;
    font-size: 14px;
    width: 23.5%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row-column-content-extractor-schedule {
    text-align: start;
    color: #395a7a;
    font-weight: 400;
    font-size: 14px;
    width: 30%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row-column-content-extractor-exec.log {
    text-align: start;
    color: #395a7a;
    font-weight: 400;
    font-size: 14px;
    width: 6%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row-column-content-extractor-schedule.actions {
    text-align: center;
    color: #395a7a;
    font-weight: 400;
    font-size: 14px;
    width: 10%;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* Schedule CSS*/

#schedules {
    background: #f1f4f9;
    height: 100%;
    width: 100%;
}

#container-schedule {
    height: 90%;
    width: 95%;
    background: white;
    margin: auto;
    border: 1px solid #C9D3DD;
    box-shadow: 0 0 10px 0 rgba(201, 211, 221, 0.4)
}

#name-schedule-collector {
    width: 50%;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.1;
    color: #395a7a;
    text-rendering: optimizelegibility;
    text-overflow: ellipsis;
    overflow: hidden;
}

.actions-schedule-collector {
    position: absolute;
    right: 0;
    display: flex;
}

.info-schedule {
    width: 90%;
    height: 35%;
    margin: auto;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.info-schedule-by-date {
    width: 70%;
    height: 100%;
    border-right: 1px solid #C9D3DD;
    display: flex;
    flex-flow: column;
    padding-right: 70px;
    position: relative;
}

.info-schedule-by-rate {
    width: 35%;
    height: 100%;
    display: flex;
    flex-flow: column;
}

.info-schedule-by-rate > div:first-child {
    width: 81%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
}

.info-schedule h5 {
    color: #395a7a;
    font-size: 18px;
}

.info-schedule-by-date h5 {
    padding-left: 8px;
}

.container-schedule-inputs {
    width: 100%;
    flex-grow: 1;
    display: flex;
}

.container-schedule-rate-inputs {
    width: 100%;
    height: 100%;
}

.container-schedule-inputs-left {
    width: 50%;
    height: 100%;
}

.container-schedule-inputs-right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.container-schedule-inputs-left-input {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.container-schedule-inputs-right-input {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.container-schedule-inputs-right-input label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px !important;
    color: #7A7A7F;
    line-height: 20px;
    font-family: proxima-nova, sans-serif;
}

/*.container-schedule-inputs-right-input div {*/
/*    width: 65%;*/
/*}*/

/*.container-schedule-inputs-left-input div {*/
/*    width: 45%;*/
/*}*/

.inputs-schedule {
    width: 45%;
}

.container-schedule-inputs-left-input label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px !important;
    color: #7A7A7F;
    line-height: 20px;
    font-family: proxima-nova, sans-serif;
}

.flat-theme {
    width: 100%;
    height: 40px;
    padding: 5px 14px 3px;
    border-radius: 1px;
    background-color: white;
    background-image: url('../images/icon-dropdown-arrow.png');
    background-position: right 15px;
    background-repeat: no-repeat;
    border-color: #C1C3C7;
    -moz-appearance: none;
    box-sizing: border-box;
    box-shadow: none;
    color: #395A7A;
    line-height: inherit;
    border: 1px solid #C9D3DD;
    font-size: 13px !important;
    font-weight: 400;
    font-family: "proxima-nova", sans-serif;
}

.fieldset-seed {
    font-size: 14px !important;
    color: #395a7a;
    font-weight: 400;
    height: 90%;
    width: 95%;
    display: flex;
    flex-flow: column;
}

.fieldset-seed > label {
    margin: 0;
    line-height: 1.7;
    color: #7a7a7f;
    font-size: 14px !important;
    display: block;
    font-family: proxima-nova, sans-serif;
}

.textarea-seed {
    padding: 5px 14px 3px;
    box-shadow: none;
    color: #395A7A;
    background: #fff;
    width: 100%;
    border: 1px solid #c1c3c7;
    border-radius: 3px;
    min-width: 0;
    display: block;
    margin: 0;
    line-height: 1.5;
    resize: none;
    font-size: 13px !important;
    transition: border linear .2s, box-shadow linear .2s;
    font-weight: 400;
    overflow: auto;
    box-sizing: border-box;
    flex-grow: 1;
}

.input-append {
    width: 100% !important;
    display: inline-table;
    vertical-align: middle;
    margin: 0 !important;
    font-size: 0;
    white-space: nowrap;
    color: #395a7a;
    font-weight: 400;
    line-height: 20px
}

#birthDate {
    width: 100%;
    padding: 5px 12px 3px;
    height: 40px;
    color: #395A7A;
    border-radius: 1px 0 0 1px;
    background-color: #fff;
    border: 1px solid #C9D3DD;
    font-size: 13px !important;
}

.add-on {
    padding: 9px;
    width: 40px;
    background-color: #F1F1F1;
    display: table-cell;
    border-radius: 0 1px 1px 0;
    border: 1px solid #C9D3DD;
    vertical-align: top;
    font-size: 15px;
    text-align: center;
    text-shadow: 0 1px 0 #fff;
}

::placeholder { /* Most modern browsers support this now. */
    opacity: 1;
}

.input-append .add-on:last-child {
    border-left: 0;
}

/* Settings CSS*/

#settings {
    background: #f1f4f9;
    height: 100%;
    width: 100%;
}


#container-settings {
    height: 90%;
    width: 95%;
    background: white;
    margin: auto;
    border: 1px solid #C9D3DD;
    box-shadow: 0 0 10px 0 rgba(201, 211, 221, 0.4)
}

.cont-settings-up {
    height: 55%;
    width: 90%;
    margin: auto;
    padding-top: 20px;
}

.cont-settings-down {
    height: 30%;
    width: 90%;
    margin: auto;
}

#title-settings {
    width: 50%;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.1;
    color: #395a7a;
    text-rendering: optimizelegibility;
    text-overflow: ellipsis;
    overflow: hidden;
}

.cont-settings-up-inputs {
    height: 50%;
    width: 100%;
    display: flex;
}

.cont-settings-up-inputs-side {
    height: 100%;
    width: 50%;
}

.cont-settings-up-inputs-mail {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.cont-settings-up-edit-mail {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.cont-settings-up-inputs-password {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.cont-settings-up-edit-password {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.cont-settings-up-inputs-mail label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px !important;
    color: #7A7A7F;
    line-height: 20px;
    font-family: proxima-nova, sans-serif;
}

.cont-settings-up-inputs-mail > div:first-child {
    width: 75%;
}

.input-disable-mail {
    width: 100%;
    padding: 5px 12px 3px;
    height: 40px;
    color: #555;
    border-radius: 1px 0 0 1px;
    background-color: #eee;
    border: 1px solid #ccc;
    font-size: 13px !important;
    pointer-events: none;
    cursor: not-allowed;
}

.input-edit-mail {
    width: 100%;
    padding: 5px 12px 3px;
    height: 40px;
    border-radius: 1px 0 0 1px;
    background-color: white;
    border: 1px solid #CCC;
    font-size: 14px !important;
    color: #395A7A;
}

.add-off {
    padding: 9px;
    width: 40px;
    background-color: #eee;
    display: table-cell;
    border-radius: 0 1px 1px 0;
    border: 1px solid #ccc;
    vertical-align: top;
    font-size: 15px;
    text-align: center;
    text-shadow: 0 1px 0 #fff;
}

.add-off i {
    color: #ccc;
}

.cont-settings-up-inputs-password label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px !important;
    color: #7A7A7F;
    line-height: 20px;
    font-family: proxima-nova, sans-serif;
}

.cont-settings-up-inputs-password > div:first-child {
    width: 75%;
}

.cont-settings-up-edit-mail-inputs {
    width: 75%;
    display: flex;
}

.cont-settings-up-edit-mail-inputs label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px !important;
    color: #395A7A;
    line-height: 20px;
    font-family: proxima-nova, sans-serif;
}

.cont-settings-up-edit-mail-left {
    width: 50%;
    margin-right: 10px;
}

.cont-settings-up-edit-mail-right {
    width: 50%;
    margin-left: 10px;
}

.cont-settings-up-edit-password-inputs {
    width: 75%;
    display: flex;
}

.cont-settings-up-credentials {
    width: 75%;
    height: 100%;
    display: flex;
}

.cont-settings-up-credentials-url {
    width: 75%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

#access-key {
    width: 40%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

#secret-key span {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 60%;
}

#access-key span {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 60%;
}

#secret-key {
    width: 35%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;

}

.div-button-new-credentials {
    width: 25%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-end;
}

.cont-settings-down-info {
    height: 100%;
    width: 88%;
    margin: auto;
    border-top: 1px solid #C9D3DD;
    display: flex;
    flex-direction: column;
}

.cont-settings-down-info h5 {
    margin-top: 10px;
    color: #395a7a;
    font-size: 14px;
    font-weight: 600;
    font-family: proxima-nova, sans-serif
}

.cont-settings-down-info label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px !important;
    color: #395A7A;
    line-height: 20px;
    font-family: proxima-nova, sans-serif
}

#input-password {
    width: 20%;
}

/* Executions CSS*/

#execution {
    background: #f1f4f9;
    height: 100%;
    width: 100%;
    position: relative;
}

#container-execution {
    height: 100%;
    width: 100%;
    background: white;
    margin: auto;
    border: 1px solid #C9D3DD;
    box-shadow: 0 0 10px 0 rgba(201, 211, 221, 0.4);
    display: flex;
    flex-flow: column;
}

.name-actions-exec {
    width: 95%;
    height: 13%;
    margin: auto;
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px dotted #C9D3DD;
}

#name-exec {
    font-size: 20px;
    font-weight: 400;
    color: #395a7a;
    text-rendering: optimizelegibility;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-flow: column;
}

#name-exec label {
    color: #c1c3c7;
    font-size: 14px;
    font-weight: 400;
}

.actions-exec {
    position: absolute;
    right: 0;
    display: flex;
}

#name-exec > div:first-child {
    display: flex;
}

#name-exec > div:first-child span.exec-warning {
    margin-left: 10px;
}

#name-exec > div:first-child span.exec-ok {
    margin-left: 10px;
}

#name-exec > div:first-child span.exec-error {
    margin-left: 10px;
}

.status-exec {
    width: 100%;
    margin: auto;
    display: flex;
}

.status-exec-progress {
    width: 77%;
    margin: auto;
    display: flex;
    flex-flow: column;
}

.exec-progress-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.exec-progress-bar {
    width: 100%;
}

.cont-exec-progress-bar {
    width: 100%;
    background-color: #ddd;
    display: flex;
}

.div-exec-progress-bar-data {
    height: 30px;
    background-color: #6EC679;
    text-align: center;
    line-height: 30px;
    color: white;
    opacity: 0.6;
}

.div-exec-progress-bar-log {
    height: 30px;
    background-color: #F7C951;
    text-align: center;
    line-height: 30px;
    color: white;
    opacity: 0.6;
}

.status-exec-info {
    width: 33%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.status-exec-info-progress {
    height: 100%;
    display: flex;
}

.status-exec-info-progress-end {
    height: 100%;
    display: flex;
}

.status-exec-info label {
    margin-bottom: 1px;
    font-size: 14px;
    color: #c1c3c7;
    font-weight: 400;
}

.status-exec-info-progress label {
    margin-bottom: 1px;
    font-size: 14px;
    color: #c1c3c7;
    font-weight: 400;
}

.status-exec-info-progress-end label {
    margin-bottom: 1px;
    font-size: 14px;
    color: #c1c3c7;
    font-weight: 400;
}

.status-exec-info span {
    color: #395A7A;
    font-size: 14px;
    font-weight: 400;
}

.status-exec-info-progress span {
    color: #395A7A;
    font-size: 14px;
    font-weight: 400;
}

.status-exec-info-progress-end span {
    color: #395A7A;
    font-size: 14px;
    font-weight: 400;
}

.exec-container-info {
    width: 90%;
    height: 50%;
    margin: auto;
    flex-flow: 1;
}

.head-data {
    width: 100%;
    height: 14%;
    margin: auto;
    display: flex;
    align-items: center;
    border-bottom: 1px dotted #C9D3DD;
    overflow: hidden;
    overflow-y: hidden;
    overflow-y: scroll;
}

.head-step {
    width: 100%;
    height: 14%;
    margin: auto;
    display: flex;
    align-items: center;
    border-bottom: 1px dotted #C9D3DD;
    overflow: hidden;
    overflow-y: hidden;
    overflow-y: scroll;
}

.head-data-column-nr {
    display: inline-block;
    font-weight: bold;
    color: #7A7A7F;
    font-size: 14px;
    text-align: start;
    width: 10%;
}

.head-step-column {
    display: inline-block;
    font-weight: bold;
    color: #7A7A7F;
    font-size: 14px;
    text-align: start;
    width: 16.66%;
}

.head-data-column-url {
    display: inline-block;
    font-weight: bold;
    color: #7A7A7F;
    font-size: 14px;
    text-align: start;
    width: 90%;
}

.row-content-extractor-exec-data {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
}

.row-content-extractor-exec-step {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
}

.row-content-extractor-exec-data > div:first-child {
    text-align: start;
    color: #395a7a;
    font-weight: 400;
    font-size: 14px;
    width: 10%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row-content-extractor-exec-data > div:last-child {
    text-align: start;
    color: #395a7a;
    font-weight: 400;
    font-size: 14px;
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row-content-extractor-exec-step div {
    text-align: start;
    color: #395a7a;
    font-weight: 400;
    font-size: 14px;
    width: 16.66%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row-history-ok {
    color: #60bd58 !important;
}

.row-history-warning {
    color: #edb10c !important;
}

.row-history-error {
    color: #f43e61 !important;
}

.row-history-message {
    font-weight: 300 !important;
}

.container-chart-exec {
    width: 100%;
    height: 100%;
    display: flex;
}

#chart-steps {
    width: 50%;
    height: 100%;
}

#chart-flow {
    width: 50%;
    height: 100%;
}

.overlay-card-bot {
    /*position: absolute;*/
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.8;
    z-index: 10000;
}

.overlay-card-bot-settings {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.8;
    z-index: 10000;
}

.overlay-schedule-add {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.7;
    z-index: 10000;
}

.overlay-card-bot-loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.overlay-modal-delete-display-flex {
    position: absolute;
    display: flex;
    flex-flow: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 86%;
    background-color: rgba(57, 90, 122, 0.6);
    z-index: 7000;
}

.overlay-modal-delete-dash-display-flex {
    position: absolute;
    display: flex;
    flex-flow: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(57, 90, 122, 0.6);
    z-index: 7000;
}

.overlay-modal-delete-bot-display-flex {
    position: absolute;
    display: flex;
    flex-flow: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(57, 90, 122, 0.6);
    z-index: 7000;
}

.overlay-modal-delete-display-none {
    position: absolute;
    display: none;
    flex-flow: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 86%;
    background-color: rgba(57, 90, 122, 0.6);
    z-index: 7000;
}

.overlay-modal-delete-dash-display-none {
    position: absolute;
    display: none;
    flex-flow: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(57, 90, 122, 0.6);
    z-index: 7000;
}

.modal-header {
    position: relative;
    font-size: 20px;
    font-weight: normal;
    padding: 10px 10px 5px;
    border-bottom: 1px solid #C9D3DD;
}

.modal-header h3 {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal-header h3 span.featured {
    color: #395A7A;
    font-weight: 600;
}

.modal-header .close {
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 24px !important;
}

.modal-header-dash {
    position: relative;
    font-size: 20px;
    font-weight: normal;
    padding: 5px 5px 2px;
    border-bottom: 1px solid #C9D3DD;
}

.modal-header-dash h3 {
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal-header-dash h3 span.featured {
    color: #395A7A;
    font-weight: 600;
}

.modal-header-dash .close {
    position: absolute;
    top: 2px;
    right: 10px;
    font-size: 24px !important;
}

.modal-body {
    padding: 15px 13px;
    font-size: 14px;
    max-height: none;
}

.modal-body-dash {
    padding: 10px 8px;
    font-size: 14px;
    max-height: none;
}

.modal-body p {
    margin-bottom: 0;
}

.modal-body-dash p {
    margin-bottom: 0;
}

.modal-footer {
    background-color: #F1F4F9;
    border-top: 1px solid #C9D3DD;
    display: flex;
    justify-content: space-between;
}

.body-modal-delete {
    width: 40%;
    height: 65%;
    background-color: white;
}

.body-modal-delete-dash {
    width: 70%;
    height: 80%;
    background-color: white;
}

.body-modal-delete-bot {
    width: 40%;
    height: 27%;
    background-color: white;
}

.flat-btn.flat-btn-danger {
    background-color: #F43E61;
    border: 1px solid #F43E61;
    color: #FFFFFF;
}

.flat-btn.flat-btn-confirm {
    border: 1px solid rgba(74, 184, 88, .8);
    background-color: rgba(74, 184, 88, .8);
    color: #FFFFFF;
}

.flat-btn.flat-btn-cancel {
    border: 1px solid rgba(74, 184, 88, .8);
    background-color: rgba(74, 184, 88, .8);
    color: #FFFFFF;
}

.flat-btn {
    background-color: #F1F4F9;
    color: #395A7A;
    border-width: 1px;
    border-style: solid;
    border-color: #C1C3C7;
    border-radius: 3px;
    padding: 12px 15px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    display: block;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    line-height: 1em;
}

/*.overlay-card-bot::after {*/
/*    content: '';*/
/*    display: block;*/
/*    position: absolute;*/
/*    left: 44%;*/
/*    top: 44%;*/
/*    width: 35px;*/
/*    height: 35px;*/
/*    border-style: solid;*/
/*    border-color: #555;*/
/*    border-top-color: rgb(85, 85, 85);*/
/*    border-top-color: transparent;*/
/*    border-radius: 50%;*/
/*    -webkit-animation: spin .8s linear infinite;*/
/*    animation: spin .8s linear infinite;*/
/*}*/

.overlay-card-bot-no-data {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.fa-robot.no-data {
    font-size: 35px;
    color: #c1c3c7;
}

.span-no-data {
    font-size: 20px;
    color: #c1c3c7;
}

.fa-robot.loading {
    font-size: 35px;
    color: #395A7A;
}

.span-loading {
    font-size: 20px;
    color: #395A7A;
}

.container-column-collector {
    width: 100%;
    height: 90%;
    display: flex;
    margin-top: 10px;
}

.container-column-exec {
    width: 100%;
    height: 90%;
    display: flex;
    margin-top: 10px;
}

.left-column-collector {
    width: 60%;
    height: 100%;
}

.left-column-exec {
    width: 50%;
    height: 100%;
}

.right-column-collector {
    width: 40%;
    height: 100%;
}

.right-column-exec {
    width: 50%;
    height: 100%;
}

.settings-collector {
    width: 93%;
    height: 15%;
    margin-bottom: 15px;
    margin-right: 7%;
    position: relative;
}

.edit-settings-collector {
    width: 93%;
    height: 20%;
    margin-bottom: 15px;
    margin-right: 7%;
    position: relative;
}

.container-status-exec {
    width: 96%;
    height: 25%;
    margin-bottom: 10px;
    display: flex;
    flex-flow: column;
}

.container-data-log-exec {
    width: 96%;
    height: 30%;
    display: flex;
    margin: auto;
    margin-bottom: 10px;
}

.container-data-log-exec-left {
    width: 48%;
    margin-right: 10px;
}

.container-data-log-exec-right {
    width: 48%;
    margin-left: 10px;
}

.container-status-exec-left {
    width: 50%;
    height: 100%;
}

.container-status-exec-right {
    width: 50%;
    height: 100%;
}

.container-debug-exec {
    width: 96%;
    height: 35%;
    margin-bottom: 15px;
    margin: auto;
}

.exec-collector {
    width: 93%;
    height: 40%;
    margin-right: 7%;
    margin-bottom: 10px;
}

.schedule-collector {
    width: 93%;
    height: 30%;
    margin-right: 7%;
    position: relative;
}

.dt-tabs-title-collector {
    color: #395a7a;
    font-weight: 600;
}

.head-extractor-collector {
    width: 100%;
    height: 14%;
    margin: auto;
    display: flex;
    align-items: center;
    border-bottom: 1px dotted #C9D3DD;
    overflow: hidden;
    overflow-y: scroll;
}

.content-extractor-exec-collector {
    width: 100%;
    height: 77%;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
}

.content-extractor-exec-links {
    width: 100%;
    height: 90%;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
}

.row-content-extractor-exec-collector {
    width: 100%;
    height: 16%;
    display: flex;
    align-items: center;
}

.row-content-debug-exec-collector {
    width: 100%;
    height: 16%;
    display: flex;
    align-items: center;
}

.row-column-content-extractor-exec-collector {
    text-align: start;
    color: #395a7a !important;
    font-weight: 400;
    font-size: 14px;
    width: 44.5%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.row-column-content-debug-step {
    text-align: start;
    color: #395a7a !important;
    font-weight: 400;
    font-size: 13px;
    font-style: italic;
    width: 20%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.row-column-content-debug-hour {
    text-align: start;
    color: #395a7a !important;
    font-weight: 400;
    font-size: 13px;
    font-style: italic;
    width: 20%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.row-column-content-debug-message {
    text-align: start;
    color: #395a7a !important;
    font-weight: 400;
    font-size: 13px;
    font-style: italic;
    width: 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.row-column-content-debug-message-error {
    text-align: start;
    color: red !important;
    font-weight: 400;
    font-size: 13px;
    font-style: italic;
    width: 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.row-column-content-extractor-exec-collector a {
    color: #395a7a;
}

.row-column-content-extractor-exec-data {
    text-align: start;
    color: #395a7a !important;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.head-column-exec-collector {
    display: inline-block;
    font-weight: bold;
    color: #7A7A7F;
    font-size: 14px;
    text-align: start;
    width: 44.5%;
}

.row-column-content-extractor-exec-collector.log {
    text-align: start;
    color: #395a7a;
    font-weight: 400;
    font-size: 14px;
    width: 10%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.content-extractor-schedule-collector {
    width: 100%;
    height: 77%;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
}

.head-column-schedule-collector {
    display: inline-block;
    font-weight: bold;
    color: #7A7A7F;
    font-size: 14px;
    text-align: start;
    width: 44%;
}

.head-column-debug-step {
    display: inline-block;
    font-weight: bold;
    color: #7A7A7F;
    font-size: 14px;
    text-align: start;
    width: 20%;
}

.head-column-debug-hour {
    display: inline-block;
    font-weight: bold;
    color: #7A7A7F;
    font-size: 14px;
    text-align: start;
    width: 20%;
}

.head-column-debug-message {
    display: inline-block;
    font-weight: bold;
    color: #7A7A7F;
    font-size: 14px;
    text-align: start;
    width: 60%;
}

.row-column-content-extractor-schedule-collector {
    text-align: start;
    color: #395a7a;
    font-weight: 400;
    font-size: 14px;
    width: 42%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row-column-content-extractor-schedule-collector.actions {
    text-align: end;
    color: #395a7a;
    font-weight: 400;
    font-size: 14px;
    width: 10%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row-content-extractor-schedule-collector {
    width: 100%;
    height: 16%;
    display: flex;
    align-items: center;
}

.column-info-collector-settings {
    width: 33%;
    display: flex;
    flex-direction: column;
    margin-top: 14px;
}

.reload-exec {
    position: absolute;
    right: 0;
    bottom: 1px;
    font-size: 20px;
    color: #395a7a;
}


.react-datepicker__input-container input {
    font-size: 14px !important;
    width: 100%;
    color: #395A7A;
}

.react-datepicker-wrapper {
    width: 100%;
    padding: 10px 10px;
    height: 40px;
    color: #395A7A;
    border-radius: 1px 0 0 1px;
    background-color: #fff;
    border: 1px solid #C9D3DD;
    font-size: 13px !important;
}

.div-url-sdk {
    width: 40%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.div-url-sdk span {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 60%;
}

.alert-seed {
    color: #F43E61;
    opacity: 0.8;
}

.alert-seed-hidden {
    color: #F43E61;
    opacity: 0.8;
    display: none;
}

.alert-parallelism {
    color: #F43E61;
    opacity: 0.8;
}

.alert-parallelism-hidden {
    color: #F43E61;
    opacity: 0.8;
    display: none;
}

.alert-webhook {
    color: #F43E61;
    opacity: 0.8;
}

.alert-webhook-hidden {
    color: #F43E61;
    opacity: 0.8;
    display: none;
}

.alert-schedule {
    color: #F43E61;
    opacity: 0.8;
    font-size: 14px;
}

.alert-schedule-hidden {
    color: #F43E61;
    opacity: 0.8;
    display: none;
}

.d-none {
    display: none;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes opacity {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-moz-keyframes opacity {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes opacity {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.wait-loading {
    text-align: center;
    color: #395A7A;
}

.wait-loading span {
    -webkit-animation-name: opacity;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;

    -moz-animation-name: opacity;
    -moz-animation-duration: 1s;
    -moz-animation-iteration-count: infinite;
}

.wait-loading span:nth-child(1) {
    -webkit-animation-delay: 100ms;
    -moz-animation-delay: 100ms;
}

.wait-loading span:nth-child(2) {
    -webkit-animation-delay: 300ms;
    -moz-animation-delay: 300ms;
}

.wait-loading span:nth-child(3) {
    -webkit-animation-delay: 500ms;
    -moz-animation-delay: 500ms;
}