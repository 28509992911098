/* Fonts */

@font-face {
    font-family: proxima-nova;
    src: url("fonts/2F58FF_4_0.eot?#iefix") format("embedded-opentype"), url("fonts/2F58FF_4_0.woff") format("woff");
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: proxima-nova;
    src: url("fonts/2F58FF_3_0.eot?#iefix") format("embedded-opentype"), url("fonts/2F58FF_3_0.woff") format("woff");
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: 'harabara';
    src: url('fonts/harabara.woff2') format('woff2'),
         url('fonts/harabara.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    color: #395a7a;
    font-family: proxima-nova,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.login-page-row {
    display: flex;
    justify-content: center;
}

.login-page-row-content-side {
    background-color: white;
    -webkit-box-flex: 0;
    flex: 0 0 36%;
    max-width: 50%;
    height: 80vh;
    padding: 0;
    box-sizing: border-box;
}

.login-page-row-content-image {
    -webkit-box-flex: 0;
    flex: 0 0 70%;
    max-width: 70%;
    height: 100vh;
    box-sizing: border-box;
    padding: 0;
    position: relative;
}

.login-page__blank-side {
    width: 100%;
    height: 100%;
}

.blank-side {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-page__content-side {
    margin: auto;
    max-width: 450px;
    padding: 5em 4em 1em 4em;
}

.ca-row {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: start;
    align-items: flex-start;
    margin-left: -15px;
    margin-right: -15px;
}


.ca-col-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
}

.ca-u-margin-bottom {
    margin-bottom: 20px !important;
}

.ca-u-margin-bottom span.log {
    font-size: 3vw;
    color: #2983de;
    font-family: harabara;
}

.ca-heading--subtitle {
    font-weight: 600;
    font-size: 22px;
}

.ca-form {
    margin: 0;
}

.ca-form-content {
    animation: fade-in .3s;
    animation-name: fade-in;
    animation-duration: 0.3s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
}

.ca-field {
    margin-bottom: 20px;
}

.ca-field > label {
    margin: 0;
    font-size: 14px;
    line-height: 1.7;
    color: #7a7a7f;
}

.ca-label {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    color: #7a7a7f;
    font-weight: 600;
}

.ca-form-control:not(select):not(textarea), .ca-input:not(select):not(textarea) {
    min-height: 40px;
    max-height: 40px;
}

.ca-form-group > label {
    font-size: 14px;
    line-height: 1.7;
    color: #7a7a7f;
}

.ca-form input {
    margin-bottom: 0;
}

.ca-form-control {
    display: block;
    width: 100%;
    margin: 0;
        margin-bottom: 0px;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #395a7a;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    box-sizing: inherit;
    border-radius: .188rem;
}

.ca-form-control, .ca-input {
    background: #fff;
    width: 100%;
    font-size: 14px;
    border: 1px solid #c1c3c7;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 10px 14px;
    color: #395a7a;
    min-width: 0;
}

.ca-label .ca-label-required-mark::before {
    content: "*";
    color: #f43e61;
}

.ca-u-margin-bottom {
    margin-bottom: 20px !important;
}

.ca-link a {
    cursor: pointer;
    color: #2687e9;
    text-decoration: none;
    font-weight: 400;
}

.ca-submit-button {
    display: block;
}

.ca-u-full-width {
    width: 100% !important;
}
.ca-btn  {
    display: inline-block;
    max-height: 40px;
    min-height: 40px;
    margin: 0;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.8;
    text-align: center;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 3px;
    box-sizing: border-box;
}

.ca-btn-primary {
    color: #fff;
    background-color: #4ab858;
}

.ca-btn:disabled {
    cursor: not-allowed;
    opacity: .4;
    pointer-events: none;
}

.ca-request-button .ca-btn {
    width: 100%;
}

.ca-u-min-margin-top {
    margin-top: 10px !important;
}

.ca-u-margin-bottom {
    margin-bottom: 20px !important;
    margin-top: 11px;
}

.ca-u-text-center {
    text-align: center !important;
}

.ca-u-margin-top {
    margin-top: 22px !important;
    margin-bottom: 22px !important;
}

/* CSS for second page login*/

.blank-side-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0) 70%, rgba(255,255,255,1));
    /* background-image: linear-gradient(to bottom, rgba(255,255,255,0) 70%, rgba(255,255,255,1)), url(../images/dots_hero-i42f3gz25y.png); */
    background-position: center;
    background-repeat: repeat;
    background-size: 400px;
    overflow: hidden;
}

.side-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0) 70%, rgba(255,255,255,1));
    overflow: hidden;
}


.side-content-left {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0) 70%, rgba(255,255,255,1));
    overflow: hidden;
}

.main-title {
    width: 100%;
    height: 20%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: auto;

}

.main-info {
    width: 100%;
    height: 55%;
    margin: auto;
}


.main-action {
    width: 60%;
    margin: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.title {
    width: 60%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.title h1 {
    font-size: 37px;
    margin-bottom: 0;
}

.title p {
    font-size: 17px;
    margin-top: 10px;
}

.info {
    display: flex;
    width: 90%;
    justify-content: space-around;
    align-items: center;
    margin: auto;
}

.info-question {
    display: flex;
    width: 90%;
    justify-content: space-around;
    align-items: center;
    margin: auto;
}

.step-info {
    width: 28%;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
    background-color: #f6f8fb;;
    opacity: .85;
    border-radius: 10px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
    padding: calc(4 * 4px) calc(6 * 4px);
}

.last-step-info {
    width: 28%;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
    background-color: #f6f8fb;;
    opacity: .85;
    border-radius: 10px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
    padding: calc(4 * 4px) calc(6 * 4px);
}

.step-row-top {
    height: 25%;
    display: flex;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
}

.step-row-down {
    height: 75%;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
}

.step-row-top-left {
    width: 25%;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
}

.step-row-top-right {
    width: 25%;
    margin-left: 50%;
}

.step-row-top-left img {
    vertical-align: middle;
    width: 82px;
    border: 0;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;

}

.step-row-top-right h3 {
    color: #2687e9;
    font-size: 70px;
    line-height: 52px;
    margin: 0;
    margin-top: calc(4 * 4px);
    font-weight: 500;
    float: right;
}

.step-row-down p {
    color: #5b627d;
    font-size: 15px;
    line-height: 22px;
    margin-top: calc(6 * 8px);
    padding-bottom: calc(6 * 8px);
    font-weight: 600;
}

.know-more {
    width: 30%;
    margin: 20px;
}

.specialist {
    width: 30%;
    margin: 20px;
}

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.btn {
    width: 100%;
    font-family: proxima-nova,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 16px;
    line-height: 100%;
    color: #fff;
    padding: 10px;
    white-space: normal;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: .2s all ease;
    transition: .2s all ease;
}

.btn-register, .btn-register:visited {
    background: #4aba58;
}

.btn-callme, .btn-callme:visited {
    border: solid 2px #385a7b;
    color: #385a7b;
}

a {
    text-decoration: none;
}

.info-question {
    font-size: 21px;
}

.step-info:hover {
    box-shadow: 0 0 20px 0 rgba(0,0,0,.08);
    background-color: #fff;
    opacity: 1;
}

.last-step-info:hover {
    box-shadow: 0 0 20px 0 rgba(0,0,0,.08);
    background-color: #fff;
    opacity: 1;
}

.step-info:hover p {
    background: url(../images/step-arrow.png) right bottom no-repeat;
}

/* .step-info:hover div.step-row-down p {
    background: url(../images/step-arrow.png) right bottom no-repeat;
} */

.main-logo {
    width: 100%;
    height: 20%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.main-form {
    width: 75%;
    height: 65%;
    margin: auto;
}

.main-programer {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 10px;
}

.logo {
        font-size: 3.5vw;
        color: #2983de;
        font-family: harabara;
}

.slogan {
    font-style: italic;
    margin-left: 12px;
    margin-top: -8px;
    font-size: 0.75vw;
}

.logo-container {
    display: flex;
    flex-flow: column;
}

.ca-col-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 15px;
    padding-right: 15px;
}

.ca-btn-default {
    color: #395a7a;
    background-color: #f1f4f9;
    border-color: #c9d3dd;
}

.ca-u-full-width {
    width: 100% !important;
}

.ca-submit-button {
    display: block;
}

.ca-input-error-messages {
    color: #DF545F;
    display: none;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border-radius: .25rem;
}
.display-error {
    display: block;
}

.display-ok {
    display: none;
}