
* {
    margin: 0;
    padding: 0;
    border: 0;
}

html, body{
    height: 100%;
    font-family: "proxima-nova",sans-serif;
}

#root {
    height: 100%;
    font-family: "proxima-nova",sans-serif;
}

ul, ol, li {
    list-style: none;
}

/* Base struct css rules */

.side-bar {
    height: 100%;
    width: 16%;
    top:0;
    left:0;
    background: white;
    position: fixed;
    overflow-x: hidden;
}

.main {
    height: 100%;
    margin-left: 16%;
    width: 84%;
    background: #f1f4f9;
    overflow: hidden;
    border-left: 1px solid #C9D3DD;
}

/* side bar css rules */

.side-bar-header {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    height: 8%;
    border-bottom: 1px solid #C9D3DD;

}

.side-bar-main {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    height: 76%;
}

.side-bar-footer {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    height: 12%;
    position: relative;
}

/* main content css rules */

.main-header {
    width: 97%;
    height: 8%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.4%;
    border-bottom: 1px solid #C9D3DD;
    display: flex;
    align-items: center;
}

.main-content {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top:2%;
    height: 85%;
}
